import { BaseEntityModel } from '@/model/base-model';
import { QueryPageModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import CommonService from '@/service/common';

export class DutyHistoryEntityModel extends BaseEntityModel {
    // @FormControl({
    //     label: '名称',
    //     type: FormControlType.TEXT,
    //     required: true
    // } as FormControlTextModel)
    name: string = undefined;
    department: string = undefined;
    position: string = undefined;
    dutyDate: string = undefined;
    dutyRange: string = undefined;
    onDutyTime: string = undefined;
    offDutyTime: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '姓名',
                dataIndex: 'name',
                width: 400
                // scopedSlots: { customRender: 'name' }
            },
            {
                title: '部门',
                dataIndex: 'department'
            },
            {
                title: '职位',
                dataIndex: 'position'
            },
            {
                title: '值班日期',
                dataIndex: 'dutyDate'
            },
            {
                title: '班次时间',
                dataIndex: 'dutyRange'
            },
            {
                title: '上班时间',
                dataIndex: 'onDutyTime'
            },
            {
                title: '下班时间',
                dataIndex: 'offDutyTime'
            }
            // {
            //     title: '操作',
            //     dataIndex: 'action',
            //     scopedSlots: { customRender: 'action' }
            // }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class DutyHistoryQueryModel extends QueryPageModel {
    @QueryControl({
        label: '部门',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: CommonService.getOrgs
    })
    department: string = undefined;

    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE
    })
    dateRange: string = undefined;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
