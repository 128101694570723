
import { DutyHistoryEntityModel, DutyHistoryQueryModel } from '@/entity-model/duty-history-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/duty-record`;

class DutyHistoryService implements ICRUDQ<DutyHistoryEntityModel, DutyHistoryQueryModel> {
    async create(model: DutyHistoryEntityModel):Promise<DutyHistoryEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<DutyHistoryEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new DutyHistoryEntityModel().toModel(res);
    }

    async update(model: DutyHistoryEntityModel):Promise<DutyHistoryEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: DutyHistoryEntityModel):Promise<DutyHistoryEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: DutyHistoryQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DutyHistoryEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new DutyRecordEntityModel().toModel(item));
    }
}

export default new DutyHistoryService();
